"use client"; // Marks this as a client-side component
import React, { useState, useEffect } from "react";
import Image from "next/image";
import { getGlobalRating } from "../../actions/globalRating";
import TrainerHeadShape from "../../assets/svg/TrainerHeadShape";
import { getImageUrlNew } from "../../lib/util";
import RightEnrollWrapper from "../molecules/RightEnrollWrapper";
import { useDictionary } from "../../context/dictionaryProvider";

// Skeleton component for loading state
const SocialRatingsSkeleton = () => {
  return (
    <div className="animate-pulse grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 md:6">
      {[...Array(3)].map((_, index) => (
        <div key={index} className="text-center">
          <div className="w-full h-12 bg-gray-300 rounded-md mb-4"></div>
          <div className="w-full h-12 bg-gray-300 rounded-md"></div>
        </div>
      ))}
    </div>
  );
};

// Shape component
const Shape = () => (
  <span className="absolute z-[11] left-0 -top-24 transform scale-x-[-1] rotate-[100deg]">
    <TrainerHeadShape height={300} width={283} classNames="hidden md:block" />
  </span>
);

const SocialRatings = ({ params, course, ratingClass }) => {
  const [globalRating, setGlobalRating] = useState(null);
  const [loading, setLoading] = useState(true);
  const dict = useDictionary();

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        setLoading(true);
        const response = await getGlobalRating(params);
        setGlobalRating(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRatings();
  }, [params]);

  return (
    <RightEnrollWrapper
      shape={<Shape />}
      className="z-0"
      btnText={dict?.enrollNow}
      btnStyle={{ padding: "16px 20px", right: "-5px" }}
      isEnquire
      course={course}
      ratingClass={ratingClass}
      enquireCss="!right-[10.7px]"
      accreImg
    >
      <p className="text-center text-base font-normal mb-8">
        {dict?.reviews?.title || "Reviews"}
      </p>
      {loading ? (
        <SocialRatingsSkeleton />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 md:6">
          {globalRating?.data?.map((review, index) => (
            <div key={index} className="text-center">
              <p className="text-3xl font-bold">{review.rating}/5</p>
              <Image
                loading="lazy"
                src={getImageUrlNew(review?.logo, "thumbnail")}
                alt={review.alt ?? `logo${index}`}
                className="mx-auto my-4"
                height={48}
                width={100}
              />
            </div>
          ))}
        </div>
      )}
    </RightEnrollWrapper>
  );
};

export default SocialRatings;

"use client";
import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import WrapImgLeft from "../assets/svg/VideoWrapImgLeft";
import WrapImgRight from "../assets/svg/VideoWrapImgRight";
import { useDictionary } from "../context/dictionaryProvider";

const VideoPlayer = ({ src, playPosition = "left", classNames, videoSize }) => {
  const playerRef = useRef(null); // Reference to the video element
  const [start, setStart] = useState(true); // Play/pause state
  const dict = useDictionary();

  useEffect(() => {
    const player = playerRef.current; // Local variable for the video element

    if (player) {
      const playVideo = () => {
        player.play(); // Play the video when it's ready
        setStart(true);
      };

      // Check if the video is ready to play
      if (player.readyState >= 3) {
        playVideo();
      } else {
        player.addEventListener("loadeddata", playVideo);
      }

      return () => {
        player?.removeEventListener("loadeddata", playVideo);
      };
    }
  }, []);

  const handlePlay = () => {
    if (start) {
      playerRef.current.pause(); // Pause the video
    } else {
      playerRef.current.play(); // Play the video
    }
    setStart(!start); // Toggle play/pause state
  };

  return (
    <>
      {/* Video Player */}
      <video
        ref={playerRef} // Attach ref to the video element
        muted
        autoPlay
        loop
        playsInline
        className={`w-full object-cover rounded-[16px] overflow-hidden videoDiv ${
          videoSize ? videoSize : "h-full"
        }`}
        title="Intro"
        src={src}
      />

      {/* Play/Pause Button */}
      <button onClick={handlePlay}>
        {playPosition === "right" && (
          <WrapImgRight src="../overwrapImg/vect1.png" />
        )}

        <Image
          className={`videoIcon ${
            dict?.dir === "rtl" ? "left-0 !right-auto" : "left-0 !right-1"
          }`}
          height={64}
          width={64}
          priority={true}
          alt="play/pause"
          src={
            start
              ? "https://d3v381xb6gm5e.cloudfront.net/media/pause_Icon_771b516840.svg"
              : "https://d3v381xb6gm5e.cloudfront.net/media/video_Icon_5b2641cd85.svg"
          }
        />
        {playPosition === "left" && <WrapImgLeft />}
      </button>
    </>
  );
};

export default VideoPlayer;

"use client";
import React from "react";
import { useDisclosure } from "@nextui-org/react";
import DownloadBrochureCourse from "../../../../../components/modal/DownloadBrochureCourse";
import { FillerBtn } from "../../../../../components/molecules";
import { useDictionary } from "../../../../../context/dictionaryProvider";

const BrochureCourse = ({ course }) => {
  const dict = useDictionary();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleClick = (e) => {
    e.stopPropagation();
    onOpen();
  };

  return (
    <>
      <FillerBtn
        onClick={handleClick}
        disabled={!course?.brochure}
        className={`${course?.brochure ? "" : "opacity-70"} !capitalize `}
      >
        {dict?.courses?.downloadBrochure}
      </FillerBtn>
      {isOpen && (
        <DownloadBrochureCourse
          document={course?.brochure}
          course={{
            id: course?.id,
            name: course?.course_name,
            discountPrice: course?.discounted_price,
            price: course?.price,
            productId: course?.product_id,
          }}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
        />
      )}
    </>
  );
};

export default BrochureCourse;

"use client";
import dynamic from "next/dynamic";
// import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { BgImgWrap } from "../molecules";

const CourseTrainer = dynamic(() => import("../CourseTrainer"));

export default function TrainerCourosel({ trainers = [], params }) {
  return (
    <>
      <BgImgWrap classNames="hidden lg:block !top-64 !md:top-[22rem]" />
      <Swiper
        loop={true}
        className="reviewCarousel w-full courseTraineCarousel"
        style={{ position: "unset" }}
        autoplay={false}
        navigation={true}
        spaceBetween={50}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation, Autoplay]}
        speed={1500}
        onSlideChange={() => {}}
        onSwiper={(swiper) => {}}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
            autoplay: true,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
      >
        {trainers?.map((trainer, index) => (
          <SwiperSlide key={index}>
            <CourseTrainer trainer={trainer} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

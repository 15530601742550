const VideoWrapImgLeft = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="114"
      height="114"
      viewBox="0 0 114 114"
      fill="none"
      className={`courseDetailsWrap ${className}`}
    >
      <path
        d="M91.9355 18.0339C92.3558 4.63729 106.82 0.429379 114 0H-7.62939e-06V114C-7.62939e-06 106.137 3.25795 100.551 6.9054 96.9319C11.0379 92.8309 17.1624 92.1017 22.9844 92.1017H43.9355C70.4451 92.1017 91.9355 70.6114 91.9355 44.1017V18.0339Z"
        fill="white"
      />
    </svg>
  );
};

export default VideoWrapImgLeft;

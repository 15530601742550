"use client";
import moveUp from "../../../public/icons/moveUp.svg";
import moveDown from "../../../public/icons/moveDown.svg";
import React, { useRef, useState } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import DownloadBrochure from "../modal/DownloadBrochure";
import {
  ContentContainer,
  H2Heading,
  H4Heading,
  Heading,
  Layout,
  Stack,
  SubHeading,
  TP,
} from "../molecules";
import { useDictionary } from "../../context/dictionaryProvider";
import Image from "next/image";

const AccordionItem = ({
  title,
  subheadings,
  isOpen,
  onClick,
  index,
  faqs,
}) => {
  const contentRef = useRef(null);
  return (
    <div
      id="curriculum"
      className="transform transition-all duration-1000 ease-linear text-start"
    >
      <button
        className={`flex justify-between items-center w-full py-7 px-6 lg:px-10 font-semibold text-base mb-2 text-start
        ${isOpen ? "bg-[#214F9A] text-[#fff]" : "bg-[#FFDA73] text-[#214F9A]"}`}
        onClick={onClick}
        aria-expanded={isOpen}
      >
        <h3 className="flex gap-3">
          <span>{index + 1}.</span>
          {title}
        </h3>
        {isOpen ? (
          <Image
            height={30}
            width={30}
            src={moveUp}
            alt="moveUp"
            loading="lazy"
          />
        ) : (
          <Image
            height={30}
            width={30}
            src={moveDown}
            alt="moveDown"
            loading="lazy"
          />
        )}
      </button>
      <div
        ref={contentRef}
        style={{
          maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0px",
        }}
        className={`overflow-hidden transition-[max-height] duration-500 ease-in-out`}
      >
        <Markdown
          className="pt-2 pb-3 px-4 lg:px-16 xl:px-32 pr-8"
          rehypePlugins={[rehypeRaw]}
          components={{
            h1: ({ children }) => (
              <Heading className="mb-3">{children}</Heading>
            ),
            h2: ({ children }) => (
              <H2Heading className="mb-3">{children}</H2Heading>
            ),
            h3: ({ children }) => (
              <SubHeading className="mb-3">{children}</SubHeading>
            ),
            h4: ({ children }) => (
              <H4Heading className="mb-3">{children}</H4Heading>
            ),
            p: ({ children }) => (
              <TP className={`${faqs ? faqs : "my-3"}`}>{children}</TP>
            ),
            a: ({ children, ...rest }) => (
              <a
                className="underline underline-offset-2 text-blue-600 hover:text-blue-800"
                {...rest}
              >
                {children}
              </a>
            ),
            ul: ({ children }) => (
              <ul className="max-w-md space-y-1 text-[#11181c] list-disc list-outside dark:text-gray-400 ml-5 marker:text-[#214f9a] marker:text-xl">
                {children}
              </ul>
            ),
            ol: ({ children }) => (
              <ol className="max-w-md space-y-1 text-[#11181c] list-decimal list-outside dark:text-gray-400 ml-5 marker:text-[#214f9a] marker:text-md">
                {children}
              </ol>
            ),
          }}
        >
          {subheadings}
        </Markdown>
      </div>
    </div>
  );
};
const Curriculum = ({ course }) => {
  const dict = useDictionary();
  const [expandedIndex, setExpandedIndex] = useState(null);
  const handleLoadMore = () => {
    setExpandedIndex(!expandedIndex);
  };
  const handleToggle = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  const accordianDataList = course?.course_modules?.map((curri) => ({
    heading: curri?.heading,
    subheadings: curri?.subheadings,
  }));

  const curriculum = expandedIndex ? accordianDataList : accordianDataList;
  return (
    <Layout className="">
      <ContentContainer>
        <Stack
          className={`flex items-center gap-4 mx-auto mb-20`}
          style={{ flexDirection: "column" }}
        >
          <h2 className="text-[32px] lg:text-5xl font-extrabold text-black">
            {dict?.curriculum.title}
          </h2>
        </Stack>
        <div
          className={`overflow-hidden h-full transform transition-max-height ease-linear duration-500 
            ${
              expandedIndex
                ? "overflow-y-auto max-h-full ease-in"
                : "overflow-hidden max-h-full ease-out"
            }`}
        >
          {curriculum?.map((item, index) => (
            <React.Fragment key={index}>
              <AccordionItem
                aria-label={item?.heading}
                title={item?.heading}
                subheadings={item?.subheadings}
                isOpen={index === expandedIndex}
                index={index}
                onClick={() => handleToggle(index)}
              />
            </React.Fragment>
          ))}
        </div>
        <div className="flex items-center justify-center w-full mt-8">
          <DownloadBrochure document={course?.brochure} course={course} />
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default Curriculum;

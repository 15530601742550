import React from "react";

const TrainerHeadShape = ({ height = 134, width = 156, classNames }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 156 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${classNames ? classNames : ""}`}
    >
      <path
        d="M52.6729 20.2635C59.611 18.1085 63.5004 17.4216 70.7285 16.9499L77.2456 16.5965L79.459 13.1756C85.4871 4.04562 95.5975 -0.038264 110.563 0.575219C123.561 1.15561 135.206 5.6344 149.311 15.2188C154.045 18.5251 155.841 20.118 155.594 21.0399C155.255 22.3075 151.449 20.6084 139.3 13.4009C117.982 0.77238 89.3169 1.73708 83.3605 15.2091C82.5938 16.918 82.7934 17.095 87.7289 18.7262C94.7499 21.1015 98.6122 23.742 101.961 28.5298C104.646 32.337 104.784 32.7445 103.858 36.2015C102.92 39.4703 102.535 39.9847 99.2558 41.3909C94.6925 43.4411 88.4224 42.8726 83.7113 39.9429C80.6895 38.0834 79.7111 36.895 77.9788 33.2196C76.8482 30.7553 75.9225 27.296 75.9556 25.3287C76.0309 23.4345 75.6565 21.6051 75.1534 21.4085C73.7058 20.5884 67.2113 21.3183 59.4552 23.0689C52.6715 24.6477 50.7917 25.4408 40.9291 31.1349C31.1818 36.8599 29.482 38.1336 24.5767 43.3034C21.6297 46.466 17.385 51.9363 15.0676 55.5147C7.50307 67.3824 3.80247 86.955 6.2973 102.074C6.77035 104.918 9.38624 111.979 12.7162 119.603C15.7086 126.642 18.1528 132.731 18.0602 133.077C17.5661 134.921 15.6824 132.501 10.5562 123.285C6.36916 115.864 4.44548 111.52 3.10626 106.838C-1.93585 88.3198 1.52823 67.3253 12.2333 51.1117C15.2165 46.662 19.2842 41.3912 21.3154 39.3418C26.232 34.3603 46.2884 22.1963 52.6729 20.2635ZM80.5537 26.3755C81.0358 31.2598 82.5234 34.005 85.9556 36.4068C90.7624 39.6709 99.0359 38.9853 100.055 35.1826C100.611 33.1083 97.8298 28.9668 94.3132 26.4189C90.9962 24.048 82.435 21.1982 81.0469 21.9996C80.5355 22.2949 80.3141 24.2734 80.5537 26.3755Z"
        fill="#0096AC"
      />
    </svg>
  );
};

export default TrainerHeadShape;

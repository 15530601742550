"use client";
import React, { useState } from "react";
import { Modal, ModalBody, ModalContent } from "@nextui-org/react";
import { useDictionary } from "../../context/dictionaryProvider";
import { ContactWrapShape, SendBtn } from "../molecules";
import InputTextField from "../atoms/InputTextField";
import PhoneField from "../atoms/PhoneField";
import { useForm } from "react-hook-form";
import { downloadBrochureAction } from "../../actions/brochure";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { bitrixSourceIds, getImageUrl, senderEmail } from "../../lib/util";
import { bitrixLeadAction } from "../../actions/bitrix";
import Loading from "../../app/[lang]/loading";
import Link from "next/link";
import ThankuModal from "./ThankuModal";
import { useSearchParams } from "next/navigation";
import { isPhoneValid } from "../../lib/validatePhone";
import { leadTemplate } from "../../emailTemplates/leadTemplate";
import { emailAction } from "../../actions/email";

const formSchema = yup
  .object({
    name: yup
      .string()
      .required()
      .min(3, "Name must be at least 3 characters long")
      .max(25, "Name cannot exceed 25 characters")
      .matches(/^[a-zA-Z0-9\s]+$/, "No special character allowed"),
    email: yup
      .string()
      .email()
      .required()
      .matches(
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Email must have a valid domain"
      ),
    phone: yup
      .string()
      .required("Phone number is required")
      .test("is-valid-phone", "Phone number is not valid", (value) => {
        if (!value) return false; // Handle empty value if required
        return isPhoneValid(value);
      }),
    message: yup
      .string()
      .trim()
      .required()
      .matches(/^[a-zA-Z0-9\s]+$/, "No special character allowed"),
  })
  .required();

const DownloadBrochureCourse = ({ document, course, isOpen, onOpenChange }) => {
  const searchParams = useSearchParams();
  const dict = useDictionary();
  const [response, setResponse] = useState("");
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data) => {
    try {
      let res = await downloadBrochureAction({
        ...data,
        course: course?.id,
      });

      await bitrixLeadAction(
        { ...data, course },
        searchParams,
        bitrixSourceIds.downloadBoucher
      );

      if (res?.error) {
        return setResponse(res?.error?.message);
      }
      if (res) {
        setResponse("Thankyou for your response");
        setSuccess(true);
      }

      try {
        const html = leadTemplate({ type: "Brochure", ...data });
        await emailAction(senderEmail, html);
      } catch (error) {
        console.log(`Error whie sending email: downloadBrochureAction`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      reset();
    }
  };
  const splitName = (fullName) => {
    const nameParts = fullName.trim().split(" ");
    return {
      firstName: nameParts[0] || "",
      lastName: nameParts.slice(1).join(" ") || "", 
    };
  };
  const trackDownloadBrochureCoursesClick = (menuName, name, email, phone, message) => {
    const { firstName, lastName } = splitName(name);
    
    webengage.user.setAttribute("we_email", email);
    webengage.user.setAttribute("we_first_name", firstName);
    webengage.user.setAttribute("we_last_name", lastName);
    webengage.user.setAttribute("we_phone", phone);
    const userId = webengage.state.getForever().luid
    webengage.user.login(email);
    webengage.track('DownloadBrochureCourses', {
      category: 'DownloadBrochureCourses',
      label: menuName,
      value: 1,
      userName: name, 
      userEmail: email, 
      userPhone: phone,
      userMessage: message,
      courseName: course.name
    });
  }; 
  return (
    <>
      {isOpen && (
        <Modal
          backdrop="blur"
          isOpen={isOpen}
          size="xl"
          classNames={{
            backdrop: "blur-md",
          }}
          scrollBehavior="outside"
          onOpenChange={onOpenChange}
          onClose={() => {
            setResponse("");
            reset();
            setSuccess(false);
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                {!isSubmitting ? (
                  <ModalBody>
                    {!success ? (
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="relative bg-[#fff] my-12 mx:0 md:mx-10"
                      >
                        <div className="px-12 pb-28 pt-10 rounded-2xl border border-black">
                          <h3 className="text-2xl text-[#303030] mb-10 font-bold capitalize">
                            {dict?.courses?.downloadBrochure}
                          </h3>
                          <InputTextField
                            label={dict?.form.fieldNames.name}
                            type={"text"}
                            isInvalid={!!errors?.name}
                            errorMessage={errors?.name?.message}
                            {...register("name")}
                          />
                          <InputTextField
                            label={dict?.form.fieldNames.email}
                            type={"email"}
                            isInvalid={!!errors?.email}
                            errorMessage={errors?.email?.message}
                            {...register("email")}
                          />
                          <PhoneField
                            label={dict?.form.fieldNames.phone}
                            name="phone"
                            value={getValues("phone")}
                            onChange={async (value) => {
                              setValue("phone", value, {
                                shouldDirty: true,
                                shouldValidate: isDirty,
                              });
                            }}
                            isInvalid={!!errors?.phone}
                            errorMessage={errors?.phone?.message}
                          />
                          <InputTextField
                            label={dict?.form.fieldNames.message}
                            type={"text"}
                            isInvalid={!!errors?.message}
                            errorMessage={errors?.message?.message}
                            {...register("message")}
                          />
                          <div className="flex items-center">
                            <span className="text-sm">{response}</span>
                            {success && document?.url && (
                              <>
                                {`: `}
                                <Link
                                  prefetch={false}
                                  href={document?.url}
                                  target="_blank"
                                  className="ml-1 text-sm underline underline-offset-2 text-blue-600 rounded hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                  {dict?.viewDetail}
                                </Link>
                              </>
                            )}
                          </div>
                        </div>
                        <SendBtn
                          type="submit"
                          className="!p-4"
                          id="download-brochure"
                          onClick={() => {
                            trackDownloadBrochureCoursesClick(
                              dict?.courses?.downloadBrochure || 'Download Brochure Courses',
                              getValues().name,
                              getValues().email,
                              getValues().phone,
                              getValues().message
                            );
                          }}
                        >
                          {dict?.download}
                        </SendBtn>
                        <ContactWrapShape />
                      </form>
                    ) : (
                      <ThankuModal
                        message={"Thank you for downloading"}
                        btnText={"open"}
                        handleClick={() => {
                          onOpenChange();
                          setResponse("");
                          reset();
                          setSuccess(false);
                          if (document?.url) {
                            return window.open(getImageUrl(document), "_blank");
                          }
                        }}
                      />
                    )}
                  </ModalBody>
                ) : (
                  <Loading />
                )}
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default DownloadBrochureCourse;

"use client"
import React from 'react'
import Rating from 'react-rating'

const StarRating = ({rating}) => {
const StarFill = () =>
{
    return(
        <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='icon'>
            <path d="M6.04759 1.59372C6.34699 0.672413 7.65039 0.672413 7.94979 1.59372L8.83219 4.3097C8.96609 4.72172 9.35009 5.00068 9.78329 5.00068H12.639C13.6078 5.00068 14.0105 6.2403 13.2268 6.8097L10.9165 8.4883C10.566 8.7429 10.4193 9.1943 10.5532 9.6063L11.4357 12.3223C11.735 13.2436 10.6805 14.0097 9.89678 13.4403L7.58649 11.7617C7.23599 11.5071 6.76138 11.5071 6.41088 11.7617L4.10059 13.4403C3.31689 14.0097 2.26239 13.2436 2.56169 12.3223L3.44419 9.6063C3.57809 9.1943 3.43139 8.7429 3.08089 8.4883L0.770586 6.8097C-0.0131139 6.2403 0.389585 5.00068 1.35839 5.00068H4.21409C4.64729 5.00068 5.03129 4.72172 5.16519 4.3097L6.04759 1.59372Z" fill="#FFCC3E"/>
        </svg>
    )
}
const StarEmpty = () =>
{
    return(
        <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='icon'>
            <path d="M8.35667 4.4642L8.35668 4.46424C8.55753 5.08226 9.1335 5.50068 9.7833 5.50068H12.639C13.1234 5.50068 13.3248 6.12047 12.9329 6.4052L13.2268 6.8097L12.9329 6.4052L10.6226 8.08377C10.0969 8.46568 9.87681 9.14279 10.0777 9.76085L10.9602 12.4768C11.1098 12.9374 10.5826 13.3205 10.1907 13.0358L7.8804 11.3572L7.88035 11.3572C7.35463 10.9753 6.64277 10.9753 6.11705 11.3572L6.11701 11.3572L3.80666 13.0358C3.41479 13.3205 2.88759 12.9374 3.03725 12.4768L3.91973 9.76081C4.12053 9.14283 3.90057 8.46573 3.37483 8.0838C3.37482 8.08379 3.37481 8.08378 3.37479 8.08377L1.06448 6.4052L1.06447 6.40519C0.672613 6.12049 0.874 5.50068 1.35836 5.50068H4.21411C4.86396 5.50068 5.43988 5.08224 5.6407 4.46422L5.6407 4.46421L6.52317 1.74823C6.67283 1.28762 7.32456 1.28753 7.47428 1.74825L8.35667 4.4642Z" stroke="#FFCC3E"/>
        </svg>
    )
}
  return (
    <>
        <Rating
            className='gap-[0.20rem]'
            emptySymbol={<StarEmpty />}
            fullSymbol={<StarFill/>}
            initialRating={rating}
            readonly
        />
    </>
  )
}

export default StarRating
"use client";
import { Button, useDisclosure } from "@nextui-org/react";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import objectiveLast from "../../../../public/objectiveLast.png";
import { Arrow, ContentContainer } from "../../../components/molecules";
import { useDictionary } from "../../../context/dictionaryProvider";
const EnquireModal = dynamic(() =>
  import("../../../components/modal/EnquireModal")
);
const Objective = ({ payload, course }) => {
  const { isOpen, onOpenChange } = useDisclosure();
  const dict = useDictionary();
  const RoundShape = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="111"
        height="109"
        viewBox="0 0 111 109"
        fill="none"
        className="absolute right-[-1px] bottom-[-1px]"
      >
        <path
          d="M21.4839 91.7572C21.4839 110.947 6.99078 108.59 0 109H111V0.00012207C111 7.45323 107.883 12.7658 104.369 16.229C100.292 20.2474 94.2628 20.938 88.5381 20.938H69.4839C42.9742 20.938 21.4839 42.4283 21.4839 68.938V91.7572Z"
          fill="#FFDA73"
        />
      </svg>
    );
  };
  const ObjectiveLayer = () => {
    return (
      <svg
        width="428"
        height="241"
        viewBox="0 0 428 241"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute bottom-12 left-0 hidden lg:block"
      >
        <path
          d="M24.7932 67.8221C23.5646 67.4039 23.3059 64.7236 24.2617 61.6037C25.2556 58.3328 27.2048 55.8137 28.6069 55.9958C107.476 66.1802 184.807 83.6174 259.83 108.144C226.124 94.6335 192.192 82.293 158.712 70.0734C104.188 50.2298 47.8122 29.6898 -11.8284 24.6412C-13.2625 24.5138 -13.6186 21.7676 -12.6486 18.4885C-11.6786 15.2095 -9.74322 12.6528 -8.32108 12.7761C51.612 17.8258 108.119 38.4105 162.764 58.3282C248.331 89.4961 336.812 121.722 417.191 171.565C418.379 172.298 418.394 175.42 417.218 178.563C416.041 181.707 414.099 183.736 412.873 183.154C290.587 124.063 160.085 85.2736 25.016 67.8324C24.9444 67.808 24.8847 67.7877 24.8132 67.7633L24.7932 67.8221Z"
          fill="black"
        />
        <path
          d="M350.309 227.525C351.563 227.861 353.269 225.778 354.21 222.653C355.204 219.382 354.986 216.205 353.719 215.576C282.517 180.162 208.557 151.63 132.57 130.272C168.095 137.797 203.156 146.42 237.776 154.892C294.122 168.736 352.395 183.032 404.763 212.017C406.026 212.709 407.849 210.625 408.868 207.361C409.886 204.096 409.7 200.895 408.45 200.206C355.838 171.06 297.431 156.728 240.943 142.878C152.5 121.174 61.0478 98.7274 -33.474 95.4278C-34.8688 95.3757 -36.6183 97.9618 -37.3891 101.228C-38.16 104.495 -37.6752 107.261 -36.3328 107.46C98.1561 126.389 228.178 166.759 350.118 227.409C350.191 227.429 350.252 227.445 350.325 227.465L350.309 227.525Z"
          fill="black"
        />
      </svg>
    );
  };
  return (
    <div className="courseObjective bg-[#FFDA73] pt-20 pb-16 w-full relative">
      <ContentContainer>
        <div className="grid grid-cols-1 md:grid-cols-[45%_1fr] lg:grid-cols-[20%_1fr] gap-[60px] items-start">
          <div>
            <h3 className="text-[32px] lg:text-5xl font-extrabold text-black">
              {payload?.title}
            </h3>
            <p className="mb-4 mt-2 text-base font-normal tracking-[0.48px] text-black leading-[170%]">
              {payload?.description}
            </p>
            <Button
              className={`viewAllBtn left-0 !right-auto min-w-0 max-w-full w-auto h-full bg-black text-white rounded-full py-3 px-4 text-[13px] z-20 relative font-semibold`}
              onClick={(e) => {
                e.stopPropagation();
                onOpenChange();
              }}
            >
              {dict?.enquireNow}
            </Button>
          </div>
          <>
            <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
              {payload?.details?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <li
                      key={index}
                      className="flex flex-col rounded-[16px] border border-black px-8 py-8 min-h-full"
                    >
                      <p className="mb-2 text-base text-[32px] lg:text-5xl font-extrabold leading-normal text-black">
                        {index + 1}
                      </p>
                      <p className="text-base text-black font-normal leading-8">
                        {item.long_description}
                      </p>
                    </li>
                  </React.Fragment>
                );
              })}
              <li className="flex flex-col min-h-full">
                <div className="relative w-full h-[333px] overflow-hidden">
                  <Image
                    src={objectiveLast.src}
                    alt="objective-image"
                    className="w-full h-full rounded-[16px]"
                    loading="lazy"
                    objectFit="cover"
                    fill
                  />
                  <p className="absolute top-12 left-[25px] z-20 text-white block text-[40px] font-extrabold leading-10">
                    {dict.readyMsg}
                  </p>
                  <Link prefetch={false} href="#" className="cursor-pointer">
                    <Arrow />
                  </Link>
                  <RoundShape />
                </div>
              </li>
            </div>
          </>
        </div>
      </ContentContainer>
      <ObjectiveLayer />
      {isOpen && (
        <EnquireModal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          course={{
            id: course?.id,
            name: course?.course_name,
            discuntPrice: course?.discounted_price,
            price: course?.price,
            productId: course?.product_id,
          }}
        />
      )}
    </div>
  );
};
export default Objective;

"use client";
import React from "react";
import { ContentContainer, Layout, Stack } from "./molecules";
import dynamic from "next/dynamic";
const CertificateCarousel = dynamic(() => import("./CertificateCarousel"));

const Certificate = ({ payload }) => {
  if(!payload) return null
  return (
    <>
      <Layout className="certifcates my-40">
        <ContentContainer>
          <Stack>
            <CertificateCarousel certificates={payload} />
          </Stack>
        </ContentContainer>
      </Layout>
    </>
  );
};

export default Certificate;

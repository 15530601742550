"use client"
import Link from "next/link";
import React from "react";
import { useDictionary } from "../../../context/dictionaryProvider";
import { Button, useDisclosure } from "@nextui-org/react";
import dynamic from "next/dynamic";

const CurriculumLink = ({course}) => {
const dict = useDictionary()
const { isOpen, onOpen, onOpenChange } = useDisclosure();
const CurriculumModal = dynamic(() => import("../../../components/modal/CurriculumModal"));

const trackCurriculumClick = (menuName) => {
  webengage.track('Curriculum', {
    category: 'Curriculum',
    label: menuName,
    value: 1,
  });
};

  return (
    <>
    <Button
      prefetch={false}
      onClick={(e) => {
        e.stopPropagation();
        onOpenChange();
        trackCurriculumClick(
            dict?.curriculum?.title || 'Curriculum',
          );
      
      }}
      className="text-[14px] text-[#0096AC] font-[500] tracking-[1.08px] uppercase bg-transparent border-none"
    >
      #{dict?.curriculum?.title}
    </Button>
    {isOpen && (
      <CurriculumModal
        course={course}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      />
    )}
    </>
  );
};

export default CurriculumLink;

"use client";
import { ChevronDownIcon, ChevronUpIcon } from "../../assets/svg";
import { useRef, useState } from "react";
import { ContentContainer, Layout, Stack } from "../molecules";
import { useDictionary } from "../../context/dictionaryProvider";
import Editor from "../molecules/Editor";
import FaqIcon from "../../assets/svg/FaqIcon";

const AccordionItem = ({ title, description, isOpen, onClick }) => {
  const contentRef = useRef(null);
  return (
    <div className="transform transition-all duration-1000 ease-linear border-b-1 border-b-[#DFDFDF] text-start">
      <button
        className="flex justify-between items-center w-full py-4 font-semibold text-base text-start"
        onClick={onClick}
        aria-expanded={isOpen}
      >
        <h3>{title}</h3>
        {isOpen ? (
          <ChevronUpIcon className="size-6 stroke-[#a1a1aa]" />
        ) : (
          <ChevronDownIcon className="size-6 stroke-[#a1a1aa]" />
        )}
      </button>
      <div
        ref={contentRef}
        style={{
          maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0px",
        }}
        className={`overflow-hidden transition-[max-height] duration-500 ease-in-out`}
      >
        {description && <Editor content={description} faqs="mt-0 mb-5" />}
      </div>
    </div>
  );
};
const FaqAccordion = ({ title, dataList }) => {
  const dict = useDictionary();
  const [expandedIndex, setExpandedIndex] = useState(null);
  const handleLoadMore = () => {
    setExpandedIndex(!expandedIndex);
  };
  const handleToggle = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  const accordianDataList = dataList?.map((faq) => ({
    title: faq?.question,
    description: faq?.answer,
  }));

  const accordianDataLists = expandedIndex
    ? accordianDataList
    : accordianDataList;
  return (
    <Layout className="">
      <ContentContainer>
        <Stack
          className={`flex items-center gap-4 mx-auto mb-20`}
          style={{ flexDirection: "column" }}
        >
          <FaqIcon />
          <h2
            className={`text-3xl lg:text-[40px] font-extrabold leading-normal`}
          >
            {title || dict?.faqs.title}
          </h2>
        </Stack>
        <div
          className={`overflow-hidden h-full transform transition-max-height ease-linear duration-500 
            ${
              expandedIndex
                ? "overflow-y-auto max-h-full ease-in"
                : "overflow-hidden max-h-[32rem] ease-out"
            }`}
        >
          {accordianDataLists?.map((item, index) => (
            <AccordionItem
              key={index}
              title={item?.title}
              description={item?.description}
              isOpen={index === expandedIndex}
              onClick={() => handleToggle(index)}
            />
          ))}
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleLoadMore}
            className="primaryLinkTxt flex gap-4 items-center justify-center mt-6 underline underline-offset-4 text-[#0096AC] bg-transparent"
          >
            {!expandedIndex && accordianDataList.length > 4
              ? dict?.readMore
              : dict?.readLess}
          </button>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default FaqAccordion;

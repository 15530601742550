const VideoWrapImgRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="103"
      height="103"
      viewBox="0 0 103 103"
      fill="none"
      className="heroWrapVect absolute top-0 right-[-5px]"
    >
      <path
        d="M19.9748 16.2589C19.5959 4.18085 6.55507 0.387116 0.0820312 0H102.861V102.779C102.861 96.1258 100.274 91.2814 97.2376 88.0132C93.2748 83.7481 87.095 83.0364 81.273 83.0364H67.9748C41.4651 83.0364 19.9748 61.546 19.9748 35.0364V16.2589Z"
        fill="#fff"
      />
    </svg>
  );
};
export default VideoWrapImgRight;

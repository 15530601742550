"use client";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useEffect, useState } from "react";
import {
  CardBody,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  CommonCard,
  ContentContainer,
  DateContainer,
  Grid,
  Layout,
  ScheduledDates,
  ScheduledTime,
  SecondaryBtn,
  Span,
  TP,
} from "../../../../../components/molecules";

import styled from "@emotion/styled";
import { useDisclosure } from "@nextui-org/react";
import { useParams } from "next/navigation";
import { getSessionsByName } from "../../../../../actions/courseNew";
import { currencyRateAction } from "../../../../../actions/currencyRates";
import { getCountryCodeAction } from "../../../../../actions/staticForms";
import { useDictionary } from "../../../../../context/dictionaryProvider";
import getCurrencyByCountryCode from "../../../../../lib/currecny";
import {
  convertTimeToAMPM2,
  formatDateWithDay,
  getLink,
} from "../../../../../lib/util";

const BookOpen = dynamic(() => import("../../../../../assets/svg/BookOpen"));
const Laptop = dynamic(() => import("../../../../../assets/svg/LaptopIcon"));

const EnquireModal = dynamic(() =>
  import("../../../../../components/modal/EnquireModal")
);

const UpcomingSession = ({ course }) => {
  const params = useParams();
  const dict = useDictionary();

  let currentCountry = "AE";
  if (typeof window !== "undefined") {
    currentCountry = localStorage?.getItem("countryCode") ?? "AE";
  }
  const [currentCurrency, setCurrentCurrency] = useState("AED");

  const { isOpen, onOpenChange } = useDisclosure();
  const [selectedSession, setSelectedSession] = useState(null);

  const [sessions, setSessions] = useState([]);
  const [online, setOnline] = useState([]);
  const [classRoom, setClassRoom] = useState([]);

  const [currencyRates, setcurrencyRates] = useState([]);

  const [selectedCurrency, setSelectedCurrency] = useState({
    currency_code: "AED",
    currency_rate: 1,
  });

  useEffect(() => {
    let isMounted = true;

    (async () => {
      const sessionData = await getSessionsByName({
        ...params,
        slug: course.slug,
      });
      if (isMounted) {
        setSessions(sessionData?.data);
      }
    })();

    (async () => {
      const rates = await currencyRateAction();
      if (isMounted) {
        setcurrencyRates(rates?.data || []);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [course, params]);

  useEffect(() => {
    if (sessions && sessions.length > 0) {
      setOnline(sessions?.filter((session) => session?.type === "online"));

      setClassRoom(
        sessions?.filter((session) => session?.type === "classroom")
      );
    }
  }, [sessions]);

  const handleClick = (slot) => {
    setSelectedSession(slot);
  };

  const handleCurrencyChange = (value) => {
    const selectedRate = currencyRates.find(
      (rate) => rate.currency_code == value
    );
    setSelectedCurrency({
      currency_code: selectedRate?.currency_code ?? "AED",
      currency_rate: selectedRate?.currency_rate ?? 1,
    });
  };

  useEffect(() => {
    if (currentCurrency && currencyRates.length > 0) {
      handleCurrencyChange(currentCurrency);
    }
  }, [currentCurrency, currencyRates]);

  useEffect(() => {
    if (currentCountry) {
      setCurrentCurrency(getCurrencyByCountryCode(currentCountry));
    }
  }, [currentCountry]);

  const RightArrow = () => {
    return (
      <svg
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.3554 12.6166L4.36289 13.1051M20.3554 12.6166C20.3554 12.6166 16.9249 11.7293 15.6095 10.0936C14.7309 9.00108 14.175 6.80257 14.175 6.80257M20.3554 12.6166C20.3554 12.6166 16.9855 13.7116 15.7724 15.4245C14.9621 16.5686 14.5414 18.797 14.5414 18.797"
          stroke="#0096AE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const OverLapImg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="174"
        height="87"
        viewBox="0 0 174 87"
        fill="none"
        className="accreOverImg"
      >
        <path
          d="M33.6774 73.2373C33.6774 88.5536 10.9585 86.6723 0 87L174 87L174 1.47245e-06C174 8.42099 164.208 13.4184 156.722 15.6514C153.851 16.5079 150.843 16.7119 147.846 16.7119L65.6774 16.7119C48.0043 16.7119 33.6774 31.0388 33.6774 48.7119L33.6774 73.2373Z"
          fill="#316BCB"
        />
      </svg>
    );
  };
  const UpcomingLayer = () => {
    return (
      <svg
        viewBox="0 0 468 241"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute -left-16 bottom-2 lg:bottom-0 lg:left-0 w-[360px] h-40 lg:w-[468px] lg:h-[241px] rotate-[4deg] lg:rotate-0"
      >
        <path
          d="M64.7932 67.822C63.5646 67.4037 63.3059 64.7235 64.2617 61.6036C65.2556 58.3327 67.2048 55.8136 68.6069 55.9957C147.476 66.1801 224.807 83.6173 299.83 108.144C266.124 94.6334 232.192 82.2929 198.712 70.0733C144.188 50.2296 87.8122 29.6896 28.1716 24.6411C26.7375 24.5137 26.3814 21.7675 27.3514 18.4884C28.3214 15.2093 30.2568 12.6527 31.6789 12.7759C91.612 17.8257 148.119 38.4104 202.764 58.3281C288.331 89.496 376.812 121.722 457.191 171.565C458.379 172.298 458.394 175.42 457.218 178.563C456.041 181.706 454.099 183.736 452.873 183.154C330.587 124.063 200.085 85.2735 65.016 67.8323C64.9444 67.8079 64.8847 67.7876 64.8132 67.7632L64.7932 67.822Z"
          fill="#ABE69E"
        />
        <path
          d="M390.309 227.525C391.563 227.861 393.269 225.777 394.21 222.653C395.204 219.382 394.986 216.205 393.719 215.576C322.517 180.161 248.557 151.629 172.57 130.272C208.095 137.797 243.156 146.42 277.776 154.892C334.122 168.736 392.395 183.032 444.763 212.017C446.026 212.709 447.849 210.625 448.868 207.361C449.886 204.096 449.7 200.895 448.45 200.206C395.838 171.06 337.431 156.728 280.943 142.878C192.5 121.174 101.048 98.7273 6.526 95.4277C5.13119 95.3755 3.38173 97.9617 2.61086 101.228C1.83999 104.494 2.32475 107.261 3.66721 107.46C138.156 126.389 268.178 166.759 390.118 227.409C390.191 227.429 390.252 227.445 390.325 227.465L390.309 227.525Z"
          fill="#ABE69E"
        />
      </svg>
    );
  };

  const CardTitleExtended = styled(CardTitle)`
    margin-bottom: 0;
  `;

  const CardFooterExtended = styled(CardFooter)`
    padding: "40px";
  `;

  return (
    <>
      <Layout
        id="upcoming-sessions"
        className="upcomingSession bg-[#316BCB] pt-20 pb-20 lg:pb-32 mt-0 md:mt-0 mb-32"
      >
        <ContentContainer session>
          <Grid upcoming className="mb-10">
            <p className="w-full text-lg lg:text-[40px] font-extrabold leading-10 text-white">
              {dict?.upcoming.title}
            </p>
            <div className="gap-1 lg:gap-6 lg:items-center flex flex-wrap justify-end">
              {course?.discounted_price && course?.discounted_price && (
                <>
                  <label className="text-[#fff] text-sm lg:text-base font-[300] tracking-[0.48px]">
                    Select currency
                  </label>
                  <select
                    className="country"
                    onChange={(e) => {
                      handleCurrencyChange(e.target.value);
                    }}
                  >
                    {currencyRates &&
                      currencyRates?.map((rate, index) => (
                        <option
                          key={index}
                          value={rate.currency_code}
                          selected={
                            selectedCurrency?.currency_code ==
                            rate.currency_code
                              ? true
                              : false
                          }
                        >
                          {rate?.currency_code}
                        </option>
                      ))}
                  </select>
                </>
              )}
            </div>
          </Grid>
          <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-[70px]">
            {/* classroom */}
            {classRoom && classRoom.length > 0 && (
              <CommonCard className="sessionCard">
                <CardContent session>
                  <CardHeader session>
                    <BookOpen color="#0096AE" />
                    {/* <CardTitle style={{ marginBottom: "0" }} session>
                      {dict?.classroomTraining.cardTitle}
                    </CardTitle> */}
                    <CardTitleExtended session>
                      {dict?.classroomTraining.cardTitle}
                    </CardTitleExtended>
                    <TP className="mb-4" session>
                      {dict?.sessions.description}
                    </TP>
                  </CardHeader>
                  <CardBody session>
                    <p className="font-medium mb-4">
                      {dict?.sessions.scheduleDate}
                    </p>
                    <ScheduledDates session>
                      {classRoom?.slice(0, 3)?.map((session, slotIndex) => (
                        <DateContainer
                          className={
                            selectedSession?.id === session?.id ? "active" : ""
                          }
                          key={slotIndex}
                          value={session?.id}
                          onClick={() => handleClick(session)}
                        >
                          <p className="">
                            {formatDateWithDay(session?.start_date)}
                          </p>
                          <ScheduledTime className="mt-2">
                            {convertTimeToAMPM2(session?.start_date)} -{" "}
                            {convertTimeToAMPM2(session?.end_date)}
                          </ScheduledTime>
                        </DateContainer>
                      ))}
                    </ScheduledDates>
                    {classRoom?.length > 0 && (
                      <Link
                        prefetch={false}
                        href={getLink(
                          `/${params?.lang}/browsecourses/${course?.category?.slug}/${course?.slug}/sessions`
                        )}
                        className="primaryLinkTxt flex gap-4 items-center mt-6"
                      >
                        <Span>{dict?.sessions.seeAll}</Span>
                        <RightArrow />
                      </Link>
                    )}
                  </CardBody>
                  {/* <CardFooter session color="#fff" style={{ padding: "40px" }}> */}
                  <CardFooterExtended session color="#fff">
                    {course?.discounted_price ? (
                      <>
                        <p className="text-[24px] font-[700] tracking-[0.72px]">
                          {selectedCurrency.currency_code}{" "}
                          {(
                            course?.discounted_price *
                            selectedCurrency.currency_rate
                          ).toFixed(2)}
                        </p>
                        <strike className="text-[16px] text-[#9F9F9F] font-[400] tracking-[0.48px]">
                          {selectedCurrency.currency_code}{" "}
                          {(
                            course?.price * selectedCurrency.currency_rate
                          ).toFixed(2)}
                        </strike>
                      </>
                    ) : course?.price ? (
                      <p className="text-[24px] font-[700] tracking-[0.72px]">
                        {selectedCurrency.currency_code}{" "}
                        {(
                          course?.price * selectedCurrency.currency_rate
                        ).toFixed(2)}
                      </p>
                    ) : (
                      ""
                    )}
                  </CardFooterExtended>
                  {/* </CardFooter> */}
                  {course?.price || course?.discounted_price ? (
                    <Link
                      prefetch={false}
                      href={getLink(
                        `/${params?.lang}/browsecourses/${course?.category?.slug}/${course?.slug}/sessions`
                      )}
                    >
                      <SecondaryBtn className="secondaryOverlap" session>
                        {dict?.applyNow}
                      </SecondaryBtn>
                    </Link>
                  ) : (
                    <SecondaryBtn
                      className={`viewAllBtn rounded-full px-[17px] py-[13px] text-[13px] z-[2] !bg-[#ABE69E] !text-black font-semibold right-2 bottom-2 absolute`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenChange();
                      }}
                    >
                      {dict?.enquireNow}
                    </SecondaryBtn>
                  )}

                  <OverLapImg />
                </CardContent>
              </CommonCard>
            )}
            {/* Online */}
            {online && online.length > 0 && (
              <CommonCard className="sessionCard">
                <CardContent session>
                  <CardHeader session>
                    <Laptop color="#0096AE" />
                    {/* <CardTitle style={{ marginBottom: "0" }} session>
                      {dict?.onlineTraining.cardTitle}
                    </CardTitle> */}
                    <CardTitleExtended session>
                      {dict?.onlineTraining.cardTitle}
                    </CardTitleExtended>
                    <TP className="mb-4" session>
                      {dict?.sessions.description}
                    </TP>
                  </CardHeader>
                  <CardBody session>
                    <p className="font-medium mb-4">
                      {dict?.sessions.scheduleDate}
                    </p>
                    <ScheduledDates session>
                      {online?.slice(0, 3)?.map((session, slotIndex) => (
                        <DateContainer
                          className={
                            selectedSession?.id === session?.id ? "active" : ""
                          }
                          key={slotIndex}
                          value={session?.id}
                          onClick={() => handleClick(session)}
                        >
                          <p className="">
                            {formatDateWithDay(session?.start_date)}
                          </p>
                          <ScheduledTime className="mt-2">
                            {convertTimeToAMPM2(session?.start_date)} -{" "}
                            {convertTimeToAMPM2(session?.end_date)}
                          </ScheduledTime>
                        </DateContainer>
                      ))}
                    </ScheduledDates>
                    {online?.length > 0 && (
                      <Link
                        prefetch={false}
                        href={getLink(
                          `/${params?.lang}/browsecourses/${course?.category?.slug}/${course?.slug}/sessions`
                        )}
                        className="primaryLinkTxt flex gap-4 items-center mt-6"
                      >
                        <Span>{dict?.sessions.seeAll}</Span>
                        <RightArrow />
                      </Link>
                    )}
                  </CardBody>
                  {/* <CardFooter session color="#fff" style={{ padding: "40px" }}> */}
                  <CardFooterExtended session color="#fff">
                    {course?.discounted_price ? (
                      <>
                        <p className="text-[24px] font-[700] tracking-[0.72px]">
                          {selectedCurrency.currency_code}{" "}
                          {(
                            course?.online_discounted_price *
                            selectedCurrency.currency_rate
                          ).toFixed(2)}
                        </p>
                        <strike className="text-[16px] text-[#9F9F9F] font-[400] tracking-[0.48px]">
                          {selectedCurrency.currency_code}
                          {(
                            course?.online_price *
                            selectedCurrency.currency_rate
                          ).toFixed(2)}
                        </strike>
                      </>
                    ) : course?.price ? (
                      <p className="text-[24px] font-[700] tracking-[0.72px]">
                        {selectedCurrency.currency_code}{" "}
                        {(
                          course?.online_price * selectedCurrency.currency_rate
                        ).toFixed(2)}
                      </p>
                    ) : (
                      ""
                    )}
                  </CardFooterExtended>
                  {/* </CardFooter> */}
                  {course?.price || course?.discounted_price ? (
                    <Link
                      prefetch={false}
                      href={getLink(
                        `/${params?.lang}/browsecourses/${course?.category?.slug}/${course?.slug}/sessions`
                      )}
                    >
                      <SecondaryBtn className="secondaryOverlap" session>
                        {dict?.applyNow}
                      </SecondaryBtn>
                    </Link>
                  ) : (
                    <SecondaryBtn
                      className={`viewAllBtn rounded-full px-[17px] py-[13px] text-[13px] z-[2] !bg-[#ABE69E] !text-black font-semibold right-2 bottom-2 absolute`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenChange();
                      }}
                    >
                      {dict?.enquireNow}
                    </SecondaryBtn>
                  )}

                  <OverLapImg />
                </CardContent>
              </CommonCard>
            )}
          </div>
        </ContentContainer>
        <UpcomingLayer />
        {isOpen && (
          <EnquireModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            course={{
              id: course?.id,
              name: course?.course_name,
              discuntPrice: course?.discounted_price,
              price: course?.price,
              productId: course?.product_id,
            }}
          />
        )}
      </Layout>
    </>
  );
};

export default UpcomingSession;

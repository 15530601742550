"use client";
import dynamic from "next/dynamic";
import {
  AccreOverImg,
  BgImg2Wrap,
  ContentContainer,
} from "../../../components/molecules";
import { Button, useDisclosure } from "@nextui-org/react";
import { useDictionary } from "../../../context/dictionaryProvider";

const ArrowRightIcon = dynamic(() =>
  import("../../../assets/svg/ArrowRightIcon")
);

// Dynamically import components
const ContentWithLinks = dynamic(() =>
  import("../../../components/ContentWithLink")
);
const EnquireModal = dynamic(() =>
  import("../../../components/modal/EnquireModal")
);

const Overview = ({ payload, about, course }) => {
  const { isOpen, onOpenChange } = useDisclosure();
  const dict = useDictionary();

  return (
    <>
      <div className="accreditations w-full relative ">
        <BgImg2Wrap overviewClass="absolute top-auto bottom-[270px] sm:top-32 sm:bottom-auto 2xl:top-[9.5rem] left-0" />
        <ContentContainer>
          <div className="px-10 md:px-20 pt-20 pb-28 relative border-[1.2px] border-black rounded-2xl z-10 bg-white lg:min-h-[30rem]">
            <div className="grid grid-cols-1 lg:grid-cols-[35%_1fr] gap-3">
              <div>
                <h3 className="mb-2 text-[32px] lg:text-5xl font-extrabold">
                  {payload?.title}
                </h3>
                <p className="text-base font-normal tracking-[0.48px] text-black leading-[170%]">
                  {payload?.description}
                </p>
              </div>
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4 lg:mt-0">
                {payload?.details?.map((overview, index) => (
                  <li className="flex text-[16px] text-black gap-3" key={index}>
                    <span>
                      <ArrowRightIcon className="stroke-[#214F9A]" />
                    </span>
                    <span>{overview?.long_description}</span>
                  </li>
                ))}
              </ul>
            </div>
            <AccreOverImg
              className={`-left-[1px] ${dict?.dir === "rtl" && "!right-auto"}`}
              styled={{ transform: "rotateY(180deg)" }}
              fill="#ffda73"
            />
            <Button
              className={`absolute left-0 right-auto bottom-[3.7px] min-w-0 max-w-full w-auto h-auto bg-black text-white rounded-full py-3 px-4 text-[13px] z-20 font-semibold`}
              onClick={(e) => {
                e.stopPropagation();
                onOpenChange();
              }}
            >
              {dict?.enquireNow}
            </Button>
          </div>
          {about && (
            <div className="md:max-w-[1600px] md:ms-24 md:me-24 mt-8 flex justify-center flex-col">
              <ContentWithLinks content={about} />
            </div>
          )}
        </ContentContainer>
        {isOpen && (
          <EnquireModal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            course={course}
          />
        )}
      </div>
    </>
  );
};

export default Overview;

const countryCurrencyMap = {
  AE: "AED",
  SA: "SAR",
};

const getCurrencyByCountryCode = (countryCode) => {
  return countryCurrencyMap[countryCode] || "USD"; // Default to USD if the country code is not found
};

export default getCurrencyByCountryCode;

"use client";
import styled from "@emotion/styled";
import { Stack } from "../../../components/molecules";
import { useDictionary } from "../../../context/dictionaryProvider";

const StackExtended = styled(Stack)`
width: auto;
flex-direction: column
`;

const EnrolledValue = ({ enrolled }) => {
  // const dict = useDictionary();
  return (
    <>
      <StackExtended
        // style={{ width: "auto", flexDirection: "column" }}
        className="flex-col"
      >
        <p className="font-semibold capitalize">{enrolled}</p>
        {enrolled && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="6"
            viewBox="0 0 120 6"
            fill="none"
          >
            <path
              d="M13.4401 6C13.1005 6 12.8104 5.72815 12.784 5.36684C12.7543 4.98832 13.0247 4.65454 13.3874 4.62357C33.785 2.87551 54.4134 1.94297 75.0681 1.8363C65.5783 1.62295 56.1313 1.54037 46.8063 1.45434C31.6252 1.32014 15.9264 1.17906 0.725481 2.77916C0.359472 2.81701 0.0396051 2.54172 0.00333399 2.1632C-0.0329372 1.78468 0.230866 1.44746 0.593577 1.4096C15.867 -0.200817 31.6021 -0.0597318 46.8195 0.0779111C70.6464 0.291258 95.2844 0.511487 119.401 2.86862C119.758 2.90303 120.025 3.22994 119.998 3.60157C119.972 3.97321 119.662 4.25537 119.309 4.23817C84.0304 2.40752 48.4286 2.99938 13.4962 5.99312C13.4764 5.99312 13.4599 5.99312 13.4401 5.99312V6Z"
              fill="#FFCC3E"
            />
          </svg>
        )}
      </StackExtended>
    </>
  );
};

export default EnrolledValue;
